import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtegoytacazes.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3301009',
  schema: 'pbi_dte_cgoytacazes',
  groupId: 'b96bf241-560a-4045-906e-1936d8f5f55',
  analiseGeral: '35922c4e-f80c-4842-9f18-601cc6e0b5db',
  analiseUsuarios: '6e1ac1a9-3396-431c-a6cd-938442e24cba',
  analiseComunicados: '522ee9ac-7e7e-40ce-937b-6f8b9856f104',
  analiseProcurador: '9bec4c71-266c-4e7b-a020-13016cd37b45',
  analiseEventos: 'e04c6202-f3f0-4582-be4b-6d278e79873c'
};
